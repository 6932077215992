import { lightColors, darkColors } from "../../style/theme/colors";
import { AlertTheme } from "./types";

export const light: AlertTheme = {
  background: lightColors.card,
};

export const dark: AlertTheme = {
  background: darkColors.card,
};
