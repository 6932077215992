/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import useAuth from 'hooks/useAuth'
import { useActiveWeb3React } from 'hooks/web3'
import { isEmpty } from 'lodash'
import React from 'react'
import { useWalletModal } from '../WalletModal'

const Authenticate: React.FC = () => {
  const { account } = useActiveWeb3React()
  const { login, logout } = useAuth()

  const { onPresentConnectModal, onPresentAccountModal } = useWalletModal(login, logout, account)

  const displayName = !isEmpty(account) ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}` : "CONNECT WALLET"

  const onClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    if (!isEmpty(account)) onPresentAccountModal()
    else onPresentConnectModal()
  }

  return (
    <>
      <div className="button-t small" style={{ pointerEvents: "none" }}>{displayName}</div>
      <div className="button-cover small" />
      <a href="#" className="button-link small w-inline-block" onClick={onClick} style={{ pointerEvents: "auto" }} />
    </>
  )
}

export default Authenticate
