import styled from 'styled-components'
import { Container } from 'reactstrap'

const Page = styled(Container)`
  min-height: calc(100vh - 64px);
  padding-top: 16px;
  padding-bottom: 16px;
`

export default Page
