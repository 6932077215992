import { Colors } from "./types";

export const baseColors = {
  failure: "#e7420d",
  primary: "#26d7eb",
  primaryBright: "#e7420d",
  primaryDark: "#1b1b1b",
  secondary: "#fffbc5",
  success: "#d6ffc5",
  warning: "#FFB237",
};

export const brandColors = {
  binance: "#F0B90B",
};

export const lightColors: Colors = {
  ...baseColors,
  ...brandColors,
  background: "#161616",
  backgroundDisabled: "rgb(153, 153, 153)",
  contrast: "#e7420d",
  invertedContrast: "#26d7eb",
  input: "#eeeaf4",
  inputSecondary: "#fffbc5",
  tertiary: "#EFF4F5",
  text: "#FFF",
  textDisabled: "#BDC2C4",
  textSubtle: "#8d8d8d",
  borderColor: "#E9EAEB",
  card: "#161616",
  gradients: {
    bubblegum: "linear-gradient(139.73deg, #E6FDFF 0%, #F3EFFF 100%)",
  },
};

export const darkColors: Colors = {
  ...baseColors,
  ...brandColors,
  secondary: "#9A6AFF",
  background: "#100C18",
  backgroundDisabled: "#3c3742",
  contrast: "#FFFFFF",
  invertedContrast: "#191326",
  input: "#483f5a",
  inputSecondary: "#66578D",
  primaryDark: "#0098A1",
  tertiary: "#353547",
  text: "#EAE2FC",
  textDisabled: "#666171",
  textSubtle: "#A28BD4",
  borderColor: "#524B63",
  card: "#27262c",
  gradients: {
    bubblegum: "linear-gradient(139.73deg, #313D5C 0%, #3D2A54 100%)",
  },
};
