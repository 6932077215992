import React, { useEffect, lazy } from 'react'
import ReactDOM from 'react-dom'
import { Router, Route, Switch } from 'react-router-dom'
import { useEagerConnect } from 'hooks/web3'
// import GlobalStyle from './style/Global'
import ToastListener from './components/Toast/ToastListener'
import Web3ReactManager from './components/Web3ReactManager'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import Navigation from './components/Navigation'
import PageLoader from './components/PageLoader'
import history from './routerHistory'
import Authenticate from './components/Authenticate'

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
const Home = lazy(() => import('./views/Home/Home'))
// const Multisend = lazy(() => import('./views/Multisend'))
// const Project = lazy(() => import('./views/Projects'))
// const NotFound = lazy(() => import('./views/NotFound'))

const App: React.FC = () => {
  // Monkey patch warn() because of web3 flood
  // To be removed when web3 1.3.5 is released
  useEffect(() => {
    console.warn = () => null
  }, [])

  useEagerConnect()

  const connectButton = document.getElementById('ConnectButton')
  const mintForm = document.getElementById('MintForm')

  const results = []
  if (connectButton) {
    results.push(ReactDOM.createPortal(<Authenticate />, connectButton))
  }

  if (mintForm) {
    results.push(ReactDOM.createPortal(<Home />, mintForm))
  }

  return (
    <Router history={history}>
      {/* <GlobalStyle /> */}

      {/* <Navigation /> */}

      

      <SuspenseWithChunkError fallback={<PageLoader />}>
        <Web3ReactManager>
          <>
            {results}

            <Switch>
              <Route path="/*">
                {/* <Home /> */}
              </Route>
            </Switch>
          </>
        </Web3ReactManager>
      </SuspenseWithChunkError>
      {/* </Menu> */}
      <ToastListener />
    </Router>
  )
}

export default React.memo(App)
